import * as React from "react";
import { IChatGlobalState, IChatGlobalAction, ChatReducer } from "../reducer";

export const initialState: IChatGlobalState = {
    unReadMessages: [],
    selectedSchool: [],
    selectedGroup: null,
    selectedThread: null
};

export const MyChatContext = React.createContext<
    [IChatGlobalState, React.Dispatch<IChatGlobalAction>]
>(null);

export const MyChatStore: React.FunctionComponent = props => {
    const [state, dispatch] = React.useReducer(ChatReducer, initialState);

    return (
        <MyChatContext.Provider value={[state, dispatch]}>
            {props.children}
        </MyChatContext.Provider>
    );
};
