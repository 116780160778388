import * as React from "react";
import * as Utils from "../../utils";
import { useService } from "@app/hooks";
import { TYPES } from "@app/service/types";
import { DebounceSelect } from "./debounce-select";
import { ChatThread, ChatThreadMessage, IChatService } from "@app/service/chat";
import "../../style/index.less";

export interface IChatThreadSelectProps {
    schoolId?: string;
    chatGroupId?: string;
    attenders?: string[];
    disabled?: boolean;
    placeholder: string;
    handleOnSearchedThread: (selectedThread: any) => void;
    withButton?: boolean;
}

const keyMapping = thread => thread.key;
const labelMapping = thread => thread.label;

export const ChatThreadSelect: React.FunctionComponent<IChatThreadSelectProps> = props => {
    const chatservice = useService<IChatService>(TYPES.IChatService);

    const searchThreads = async (
        searchKey: string
    ): Promise<Array<ChatThread>> => {
        return await chatservice.searchThreads({
            groupId: props.chatGroupId,
            attenders: props.attenders,
            searchKey,
            schoolIds: props.schoolId
        });
    };

    return (
        <div className="search-thread-lookfor">
            <DebounceSelect
                filter
                keyMapping={keyMapping}
                labelMapping={labelMapping}
                placeholder={props.placeholder}
                fetchFn={searchThreads}
                fetchFnDeps={[]}
                handleOnSelectItemChange={props.handleOnSearchedThread}
                dataConvert={Utils.threadSearchDataConvert}
                disabled={props.disabled}
                autoClear={props.chatGroupId || props.attenders}
                withButton={props.withButton}
            />
        </div>
    );
};
