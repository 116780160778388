import {
    ChatThread,
    ChatThreadMessage,
    ChatUnreadMessageModel
} from "@app/service/chat";

export const threadSearchDataConvert = (source: any) => {
    const data = source.data as any[];

    if (!data) {
        return [];
    }

    const threadData = data
        .filter(d => !d.messages)
        .map((t, index) => ({
            key: `${t.chatThreadId}-${index}`,
            label: `Thread: ${t.chatThreadTitle}`,
            content: t.chatThreadTitle,
            prefix: "Thread: ",
            suffix: "",
            id: t.chatThreadId
        }));

    const convert = (model: ChatThreadMessage) =>
        model.messages
            ? model.messages.map(x => {
                  x["chatThreadId"] = model.chatThreadId;
                  x["chatThreadTitle"] = model.chatThreadTitle;

                  return x;
              })
            : [];

    const parseMessage = (message: string) => {
        const span = document.createElement("span");
        span.innerHTML = message;

        return span.textContent || span.innerText;
    };

    const messageData = data
        .filter(d => d.messages && d.messages.length !== 0)
        .reduce((fir, sed) => fir.concat(convert(sed)), [])
        .map((message, index) => ({
            key: `${message.chatThreadId}-${message.id}-${index}`,
            label: `Message: ${parseMessage(message.message)} (Thread: ${
                message.chatThreadTitle
            })`,
            content: parseMessage(message.message),
            prefix: "Message: ",
            suffix: `(Thread: ${message.chatThreadTitle})`,
            id: message.chatThreadId,
            messageId: message.id
        }));

    return [...threadData, ...messageData];
};

export const parseCount = (messages: Array<ChatUnreadMessageModel>): number => {
    if (!messages) {
        return 0;
    }

    return messages.reduce(
        (sum, m) => sum + m.unReadMessages.reduce((s, t) => s + t.count, 0),
        0
    );
};

export const updateThread = (
    thread: ChatThread,
    searchedThread: any
): ChatThread => {
    return searchedThread.messageId
        ? { ...thread, messageId: searchedThread.messageId }
        : thread;
};
