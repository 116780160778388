import {
    ChatGroupModel,
    ChatSchoolDataContract,
    ChatThread,
    ChatUnreadMessageModel
} from "@app/service/chat";

export interface IChatGlobalState {
    unReadMessages: ChatUnreadMessageModel[];
    selectedSchool: ChatSchoolDataContract[];
    selectedGroup: ChatGroupModel;
    selectedThread: ChatThread;
}

export enum ChatActionType {
    "UPDATE_UNREADMESSAGES",
    "UPDATE_SELECTED_SCHOOL",
    "UPDATE_SELECTED_GROUP",
    "UPDATE_SELECTED_THREAD"
}

export interface IChatGlobalAction {
    type: ChatActionType;
    payload: any;
}

export const ChatReducer = (
    state: IChatGlobalState,
    action: IChatGlobalAction
): IChatGlobalState => {
    switch (action.type) {
        case ChatActionType.UPDATE_UNREADMESSAGES:
            return {
                ...state,
                unReadMessages: action.payload
            };
        case ChatActionType.UPDATE_SELECTED_SCHOOL:
            return {
                ...state,
                selectedGroup: null,
                selectedThread: null,
                selectedSchool: action.payload
            };
        case ChatActionType.UPDATE_SELECTED_GROUP:
            return {
                ...state,
                selectedThread: null,
                selectedGroup: action.payload
            };
        case ChatActionType.UPDATE_SELECTED_THREAD:
            return {
                ...state,
                selectedThread: action.payload
            };
        default:
            return state;
    }
};
