import * as React from "react";
import { Select, Spin, Icon, Button } from "antd-min";
import { useKeyPress } from "@app/hooks/useKeyPress";
import { KeyText } from "@app/util";

export type FnReturnPromise<T> = (...args: any[]) => Promise<Array<T>>;

export interface IDebounceSelectProps<T extends {}> {
    filter?: boolean;
    placeholder: string;
    keyMapping: (item: T | any) => string | number;
    labelMapping: (item: T | any) => string;
    fetchFn: FnReturnPromise<T>;
    fetchFnDeps: Array<any>;
    handleOnSelectItemChange: (selectItemKey: T) => void;
    dataConvert?: (item: T) => Array<any>;
    disabled?: boolean;
    autoClear?: any;
    withButton?: boolean;
}

const { Option } = Select;

export const highlightLabel = (
    label: string,
    keyword: string,
    index,
    content?: string,
    prefix?: string,
    suffix?: string
) => {
    let newLabel = label;

    if (content && prefix && suffix && newLabel && newLabel.length > 30) {
        const re = new RegExp(
            `([\\w\\W]{0,12}${keyword}([\\w\\W]){0,12})`,
            "i"
        );
        const wordsArray = re.exec(content);

        newLabel =
            wordsArray && wordsArray[0]
                ? `${prefix}${wordsArray[0]}${suffix}`
                : newLabel;
    }

    const matchKeyword = new RegExp(keyword, "i");

    return (
        <span key={index} className="search-keyword-style">
            <p>
                {newLabel.split(matchKeyword).reduce((prev, current, index) => {
                    if (!index) {
                        return [current];
                    }

                    return prev.concat(
                        <b key={keyword + current + index}>
                            {matchKeyword.exec(newLabel)
                                ? matchKeyword.exec(newLabel)[0]
                                : ""}
                        </b>,
                        current
                    );
                }, [])}
            </p>
        </span>
    );
};

export const restore = <T extends {}>(arr: T[], key: string) => {};

export function DebounceSelect<T>(props: IDebounceSelectProps<T>) {
    const [selectItem, setSelectItem] = React.useState<
        { key: string | number; label?: React.ReactNode } | string | undefined
    >();
    const selectRef = React.useRef(null);
    const enterPress = useKeyPress(KeyText.Enter);
    const [keywords, setKeywords] = React.useState<string>("");
    const [dataSource, setDataSource] = React.useState<T[]>();
    const [loading, setLoading] = React.useState<boolean>(false);

    const asyncFetchData = () => {
        if (props.fetchFn && keywords.length > 2) {
            setLoading(true);
            props.fetchFn(keywords).then(data => {
                setDataSource(data);
                setLoading(false);
            });
        }
    };

    React.useEffect(() => {
        enterPress && asyncFetchData();
    }, [enterPress]);

    const handleOnSearchItem = React.useCallback(
        (keyWords: string) => setKeywords(keyWords),
        []
    );

    const dataValue = React.useMemo(
        () =>
            dataSource && props.dataConvert
                ? props.dataConvert(dataSource as any)
                : dataSource,
        [dataSource]
    );

    React.useEffect(() => {
        props.autoClear && setSelectItem(undefined);
        props.autoClear && setDataSource([]);
    }, [props.autoClear]);

    React.useEffect(() => {
        (!keywords || keywords.length === 0) && setDataSource([]);
    }, [keywords]);

    const handleOnSelectItemChange = React.useCallback(
        (
            selectItem:
                | { key: string | number; label: React.ReactNode }
                | undefined
        ) => {
            setSelectItem(selectItem);

            selectItem &&
                selectItem.key &&
                dataValue &&
                props.handleOnSelectItemChange(
                    (dataValue as T[]).find(
                        x => props.keyMapping(x) === selectItem.key
                    )
                );
            setSelectItem({ key: "" });
            setDataSource([]);
        },
        [
            dataSource,
            props.keyMapping,
            props.handleOnSelectItemChange,
            dataValue
        ]
    );

    const notFoundCoutent = React.useMemo(
        () => (loading ? <Spin size="small" /> : null),
        [loading]
    );

    const optionItem = React.useCallback(
        (item: T, index) =>
            props.filter
                ? highlightLabel(
                      props.labelMapping(item),
                      keywords,
                      index,
                      item.content,
                      item.prefix,
                      item.suffix
                  )
                : highlightLabel(props.labelMapping(item), keywords, index),
        [props.labelMapping, keywords]
    );

    return (
        <>
            <Select
                ref={selectRef}
                autoFocus
                showSearch
                labelInValue
                disabled={props.disabled}
                value={selectItem}
                defaultActiveFirstOption={false}
                placeholder={props.placeholder}
                notFoundContent={notFoundCoutent}
                filterOption={false}
                onSearch={handleOnSearchItem}
                onChange={handleOnSelectItemChange}
            >
                {keywords &&
                    dataValue &&
                    (dataValue as T[])
                        .filter(t =>
                            props
                                .labelMapping(t)
                                .toLowerCase()
                                .includes(keywords.toLowerCase())
                        )
                        .map((item, index) => (
                            <Option
                                key={props.keyMapping(item)}
                                className="debounce-select-item-style"
                            >
                                {optionItem(item, index)}
                            </Option>
                        ))}
            </Select>
            {props.withButton && (
                <Button
                    disabled={props.disabled}
                    onMouseDown={e => {
                        e.preventDefault();
                        asyncFetchData();
                    }}
                >
                    <Icon
                        type="search"
                        className={
                            props.disabled && "chat-search-icon-disabled"
                        }
                    >
                        Search
                    </Icon>
                </Button>
            )}
        </>
    );
}
