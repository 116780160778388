import React from "react";
import { List, Spin } from "antd-min";
import { useAsyncFn } from "@app/hooks/useAsyncFn";
import { Avatar, Badge } from "antd-min";
import "../../style/index.less";

export interface IAsyncLoadListProps<T> {
    fetchFn: (...args: any[]) => Promise<Array<T>>;
    filterFn?: (data: Array<T>) => Array<T>;
    deps: any[];
    keyMapping: (item: T) => string;
    titleMapping: (item: T) => string | number | React.ReactNode;
    descMapping?: (item: T) => string | number | React.ReactNode;
    contentMapping?: (item: T) => string | number | React.ReactNode;
    floatUpData?: (data: Array<T>) => void;
    handleOnSelect?: (item: T) => void;
    fetchAvatar?: (...args: any[]) => Promise<string>;
    includeItem?: T;
    updateItem?: (T, any) => T;
    defaultSelectNewItem?: boolean;
    reload?: boolean;
    badgeCount?: { [id: string]: number };
    autoClear?: any;
    defaultSelectItem?: T;
    extraItems?: Array<T>;
}

const calcuteSelectItemCss = (item1: string, item2: string) =>
    item1 === item2
        ? "async-load-list-item-selected"
        : "async-load-list-item-unselected";

function AsyncLoadListComponent<T>(props: IAsyncLoadListProps<T>) {
    const [dataSource, asyncFetchFn, setDataSource] = useAsyncFn<T>(
        props.fetchFn,
        [...props.deps, props.reload]
    );
    const itemRef = React.useRef<HTMLInputElement>(null);
    const currentItemRef = React.useRef<T>(null);

    const [selectItem, setSelectItem] = React.useState<T>(null);
    const [avatarData, setAvatarData] = React.useState<
        Array<{ id: string; avatar: string }>
    >([]);

    const haveItem = React.useCallback(
        (data: T[], item: T) =>
            data &&
            data.find(x => props.keyMapping(x) === props.keyMapping(item)),
        [props.keyMapping]
    );

    React.useEffect(() => {
        props.deps.every(x => !!x) && asyncFetchFn(...props.deps);
    }, [asyncFetchFn]);

    React.useEffect(() => {
        setSelectItem(null);

        dataSource.data &&
            props.floatUpData &&
            props.floatUpData(dataSource.data as T[]);
    }, [dataSource]);

    React.useEffect(() => {
        props.autoClear && setDataSource({ data: [] });
    }, [props.autoClear]);

    React.useEffect(() => {
        if (!dataSource.data || !props.extraItems) {
            return;
        }

        const extras = props.extraItems.filter(
            item =>
                !(dataSource.data as T[]).some(
                    t => props.keyMapping(t) === props.keyMapping(item)
                )
        );

        if (extras.length === 0) {
            return;
        }

        setDataSource(source => ({
            data: [...extras, ...source.data]
        }));
    }, [props.extraItems]);

    React.useEffect(() => {
        const toKeys = (arr: T[]) =>
            arr.map(t => props.keyMapping && props.keyMapping(t));

        const asyncFetchAvatars = async (keys: string[]) => {
            const avatars = await Promise.all(
                keys.map(async key => ({
                    id: key,
                    avatar: await props.fetchAvatar(key)
                }))
            );

            setAvatarData(avatars);
        };

        props.fetchAvatar &&
            dataSource.data &&
            asyncFetchAvatars(toKeys(dataSource.data as T[]));
    }, [dataSource]);

    React.useEffect(() => {
        if (
            !props.defaultSelectItem ||
            !props.keyMapping(props.defaultSelectItem) ||
            !dataSource.data
        ) {
            return;
        }

        const existItem = haveItem(
            dataSource.data as T[],
            props.defaultSelectItem
        );

        existItem && setSelectItem(existItem);
    }, [props.defaultSelectItem, dataSource]);

    React.useEffect(() => {
        if (
            !props.includeItem ||
            !props.keyMapping(props.includeItem) ||
            !dataSource.data
        ) {
            return;
        }

        const existItem = haveItem(dataSource.data as T[], props.includeItem);

        existItem && props.updateItem
            ? setSelectItem(props.updateItem(existItem, props.includeItem))
            : setSelectItem(existItem);

        !existItem &&
            setDataSource(ds => ({
                data: (ds.data as T[]).concat([props.includeItem])
            }));
    }, [props.includeItem, dataSource]);

    React.useEffect(() => {
        selectItem && props.handleOnSelect && props.handleOnSelect(selectItem);
    }, [selectItem]);

    const avatarRenderData = React.useMemo(() => {
        return props.fetchAvatar && avatarData
            ? avatarData.reduce((map, x) => {
                  map[x.id] = (
                      <Avatar
                          className="school-user-header-avatar-cover"
                          shape="circle"
                          icon="user"
                          src={x.avatar}
                      />
                  );

                  return map;
              }, {})
            : {};
    }, [avatarData]);

    const onRenderItem = React.useCallback(
        (item: T) => (
            <List.Item
                key={props.keyMapping(item)}
                onClick={() => {
                    setSelectItem(item);
                }}
                className={`async-load-list-item ${selectItem &&
                    calcuteSelectItemCss(
                        props.keyMapping(item),
                        props.keyMapping(selectItem)
                    )}`}
            >
                <List.Item.Meta
                    className="async-load-list-item-meta"
                    avatar={avatarRenderData[props.keyMapping(item)]}
                    title={
                        <pre
                            className="async-load-list-item-title"
                            dangerouslySetInnerHTML={{
                                __html: props.titleMapping(item) as any
                            }}
                        />
                    }
                    description={props.descMapping && props.descMapping(item)}
                />
                {props.contentMapping && props.contentMapping(item)}
                {props.badgeCount && (
                    <Badge count={props.badgeCount[props.keyMapping(item)]} />
                )}
            </List.Item>
        ),
        [avatarRenderData, selectItem, dataSource, props.badgeCount]
    );

    return (
        <List
            dataSource={dataSource.data as T[]}
            renderItem={onRenderItem}
            className="async-load-list"
        >
            {dataSource.loading && (
                <div className="load-spin-layout">
                    <Spin className="load-spin" />
                </div>
            )}
        </List>
    );
}

export const AsyncLoadList = React.memo(AsyncLoadListComponent);
