import * as React from "react";
import { KeyText } from "@app/util";

export function useKeyPress(targetKey: KeyText) {
    const [keyPressed, setKeyPressed] = React.useState<boolean>(false);

    const downHandler = ({ key }) => {
        key === targetKey && setKeyPressed(true);
    }

    const upHandler = ({ key }) => {
        key === targetKey && setKeyPressed(false);
    }

    React.useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);

        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, []); 

    return keyPressed;
}